import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Card, Modal, Alert } from 'react-bootstrap';
import { useOutletContext, useNavigate } from 'react-router-dom';
//import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

//TBD: see if changing to match pagination component pattern fixes errors
function MappingsModal(props) {
    const { handlemapbutton, datafile } = props

    return (
        <Modal
            {...props}    
            handlemapbutton
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Mappings</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Specify the mappings below for this file:</h6>
                
                <Container>

                {(() => {
                    if (!datafile.ownersMapped) {
                        return <Row style={{ marginTop: '40px' }} >
                            <Col xs={3} >
                                Owner Mappings:
                            </Col>
                            <Col xs={9} >
                                <Button
                                    type='button'
                                    size='sm'
                                    style={{ width: '200px' }}
                                    disabled={false}
                                    onClick={() => handlemapbutton('owners')}
                                >
                                    Map Owners
                                </Button>
                            </Col>
                        </Row>                        
                    }
                })()}
                
                {(() => {
                    if (!datafile.cdexCodesMapped) {
                        return <Row style={{marginTop: '20px'}} >
                            <Col xs={3} >
                                CDEX Code Mappings:
                            </Col>
                            <Col xs={9} >
                                <Button
                                    type='button'
                                    size='sm'
                                    style={{  width: '200px' }}
                                    disabled={false}
                                    onClick={() => handlemapbutton('cdexCodes')}
                                >
                                    Map CDEX Codes
                                </Button>
                            </Col>
                        </Row>

                        
                    }
                    })()}

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const DataFiles = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [operators, setOperators] = useState([]);
    const [dataFiles, setDataFiles] = useState([]);
    const [selectedDataFile, setSelectedDataFile] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [statusItems, setStatusItems] = useState(['', 'File Load Processing', 'Mapping Needed', 'Ready to Send', 'Sent']);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 50,
        operatorId: 0,
        fromMonth: '',
        fromYear: '',
        toMonth: '',
        toYear: '',
        statusId: 0
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [screenHeight, setScreenHeight] = useState(100);

    const [modalShow, setModalShow] = React.useState(false);

    const [alertShow, setAlertShow] = React.useState(false);
    const [alertVariant, setAlertVariant] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('Statements sent successfully!');

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            //get inner browser window height
            const height = window.innerHeight;
            setScreenHeight(height);

            await initializeForm()
                .then(response => {
                    setOperators(response.data);
                    setYears(response.dates.years);
                    setMonths(response.dates.months);
                    setDataCriteria({
                        ...dataCriteria,
                        operatorId: response.data[0].id,
                        fromMonth: response.dates.fromMonth,
                        fromYear: response.dates.fromYear,
                        toMonth: response.dates.currMonth,
                        toYear: response.dates.currYear
                    });

                    let currentCriteria = {
                        skipCount: 0,
                        takeCount: 50,
                        operatorId: response.data[0].id,
                        fromMonth: response.dates.fromMonth,
                        fromYear: response.dates.fromYear,
                        toMonth: response.dates.currMonth,
                        toYear: response.dates.currYear,
                        statusId: 0
                    };

                    //get dataFileState from local storage
                    const sessionData = localStorage.getItem('dataFileState');
                    console.log('Datafile State: ' + sessionData);
                    if (sessionData) {
                        const sessionDataObject = JSON.parse(sessionData);

                        //check sessionData
                        currentCriteria = sessionDataObject.dataCriteria;
                        setDataCriteria(currentCriteria);
                        setCurrentPage(sessionDataObject.currentPage);

                        //todo: fix to set pagination page number correctly when currentPage > 1
                    }

                    populateDataFilesInitial(currentCriteria)
                        .then(response => {
                            if (response.data.length === 0) {
                                console.log(response.data);
                                setDataStatus('nodata')
                            }
                            else {
                                //console.log('DataFile Count: ' + response.data[0].rowCount);
                                setRowCount(response.data[0].rowCount);
                                setDataFiles(response.data);
                                setDataStatus('ready')
                            }

                            setPageStatus('ready')
                        })
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, [window.innerHeight]);

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOperators() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        let dates = await getDates();

        //get operators for dropdown
        let operators = await getOperators();
        
        return { success: true, data: operators.data, dates: dates };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = () => {

        const date = new Date();
        //const day = date.getDate();
        const currMonth = date.getMonth() + 1;
        const currYear = date.getFullYear();

        //const fromMonth = currMonth === 1 ? 12 : currMonth - 2;
        //const fromYear = currMonth === 1 ? currYear - 1 : currYear;
        const fromMonth = currMonth < 3 ? (12 + currMonth) - 2 : currMonth - 2;
        const fromYear = currMonth < 3 ? currYear - 1 : currYear;

        const years = [currYear - 3, currYear - 2, currYear - 1, currYear, currYear + 1];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return { months: months, years: years, currMonth: currMonth, currYear: currYear, fromMonth: fromMonth, fromYear: fromYear };
    };

    //get operators
    const getOperators = async () => {
        //console.log('Getting Operators...');

        console.log('AccountId: ' + currentAccount.id);

        const apiUrl = 'accounts/' + currentAccount.id + '/managedsenders';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle form submit
    const submitForm = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        setCurrentPage(1);

        const currentCriteria = {
            ...dataCriteria,
            skipCount: 0
        };

        //save sessionData in local storage
        const sessionData = { dataCriteria: currentCriteria, currentPage: 1 };
        const sessionDataString = JSON.stringify(sessionData);
        localStorage.setItem('dataFileState', sessionDataString);

        await populateDataFilesInitial(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('DataFile Count: ' + response.data[0].rowCount);
                    setRowCount(response.data[0].rowCount);
                    setDataFiles(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle data file status changes
    const handleStatusChange = async (e, dataFileId, currentStatusId, arrayIndex) => {
        e.preventDefault();

        const newStatusId = currentStatusId + 1;
        //alert('Status Change: ' + e.target.name + ' for ' + dataFileId + ' at index ' + arrayIndex + ' from ' + currentStatusId + ' to ' + newStatusId);

        await updateDataFileStatus(dataFileId, newStatusId)
            .then(response => {
                if (response.success) {
                    //https://stackoverflow.com/questions/29537299/how-can-i-update-state-item1-in-state-using-setstate
                    let dFiles = [...dataFiles];
                    let dFile = { ...dFiles[arrayIndex] };
                    dFile.statusId = newStatusId;
                    dFiles[arrayIndex] = dFile;
                    setDataFiles(dFiles);

                    setAlertMessage("Statements sent successfully!");
                    setAlertShow(true);
                    setAlertVariant('success');
                }
                else {
                    setAlertMessage("Send failed.");
                    setAlertShow(true);
                    setAlertVariant('danger');
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                setTimeout(() => {
                    setAlertShow(false);
                }, 5000);
            })
    };

    //update datafile status
    const updateDataFileStatus = async (dataFileId, newStatusId) => {
        console.log('Updating DataFile Status...');

        const apiUrl = 'datafiles/' + dataFileId + '/status';
        const jsonBody = JSON.stringify({ id: dataFileId, statusId: newStatusId });
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok, statusId: newStatusId };
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        console.log('Current Page: ' + currentPage);

        setDataStatus('loading');

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        console.log('Skip Count: ' + skipCount);
        setDataCriteria({
            ...dataCriteria,
            skipCount: skipCount
        });

        const currentCriteria = {
            ...dataCriteria,
            skipCount: skipCount
        };

        //save sessionData in local storage
        const sessionData = { dataCriteria: currentCriteria, currentPage: 1 };
        const sessionDataString = JSON.stringify(sessionData);
        localStorage.setItem('dataFileState', sessionDataString);

        await populateDataFilesInitial(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    console.log('Check Count: ' + response.data[0].checkCount);
                    setDataFiles(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //populate datafiles
    const populateDataFilesInitial = async (currentCriteria) => {
        //console.log('Getting DataFiles...');

        const apiUrl = 'operators/' + currentCriteria.operatorId + '/datafiles?skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount + '&fromMonth=' + currentCriteria.fromMonth + '&fromYear=' + currentCriteria.fromYear + '&toMonth=' + currentCriteria.toMonth + '&toYear=' + currentCriteria.toYear + '&statusId=' + currentCriteria.statusId;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle mappings button
    const handleMappingsButton = async (e, dataFile) => {
        e.preventDefault();

        console.log('Owners Mapped: ' + dataFile.ownersMapped);
        console.log('Cdex Codes Mapped: ' + dataFile.cdexCodesMapped);

        setDataStatus('loading');

        setSelectedDataFile(dataFile);
        setModalShow(true)

        setDataStatus('ready')
    };

    const mappingButtonClick = (mappingType) => {

        console.log('Mapping Type: ' + mappingType);

        switch (mappingType) {
            case 'owners':
                navigate('/mappings/' + selectedDataFile.id);
                break;
            case 'cdexCodes':
                navigate('/unmappedcdex/' + selectedDataFile.id);
                break;
            default:
            //do nothing
        }
    };

    ////render page loading
    ////note: spinner overlay will eventually be at the page level (I think)
    //const showPageLoading = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    ////render page loading
    //const showDataLoading = () => {
    //    return (
    //        <Row>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </Row>
    //    );
    //}

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    ////render loading
    //const showLoading_BAK = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{backgroundColor: 'lightgray'}}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{ height: (screenHeight - 410).toString() + 'px', overflow: "auto" }}>             
                    {(() => {
                        switch (dataStatus) {
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <p>No Data Loaded.</p>
                        }
                    })()}
                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'lightgray' }}>
                    <Container fluid style={{ height: '40px' }} >
                        {(() => {
                            if (dataStatus == 'ready') {
                                return <Row>
                                    <Col className="d-flex justify-content-end">
                                        <PaginationComponent
                                            itemsCount={rowCount}
                                            itemsPerPage={dataCriteria.takeCount}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageClickHandler={handlePageClick}
                                            alwaysShown={true}
                                        />
                                    </Col>
                                </Row>
                            }
                        })()}
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container fluid>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formOperator">
                                <Form.Label>Operated Company</Form.Label>
                                <Form.Select name="operatorId" size="sm" aria-label="Operator" onChange={handleChange} >
                                    {operators.map((operator, i) => (
                                        <option key={i} value={operator.id}>{operator.companyName}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="fromMonth">
                                <Form.Label>From</Form.Label>
                                <Form.Select name="fromMonth" size="sm" value={dataCriteria.fromMonth} aria-label="FromMonth" onChange={handleChange} >
                                    {months.map((month, i) => (
                                        <option key={i} value={i+1}>{month}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="fromYear">
                                <Form.Label>&nbsp;</Form.Label>
                                <Form.Select name="fromYear" size="sm" value={dataCriteria.fromYear} aria-label="FromYear" onChange={handleChange} >
                                    {years.map((year, i) => (
                                        <option key={i} value={year}>{year}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="toMonth">
                                <Form.Label>To</Form.Label>
                                <Form.Select name="toMonth" size="sm" value={dataCriteria.toMonth} aria-label="ToMonth" onChange={handleChange} >
                                    {months.map((month, i) => (
                                        <option key={i} value={i + 1}>{month}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="toYear">
                                <Form.Label>&nbsp;</Form.Label>
                                <Form.Select name="toYear" size="sm" value={dataCriteria.toYear} aria-label="ToYear" onChange={handleChange} >
                                    {years.map((year, i) => (
                                        <option key={i} value={year}>{year}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formStatus">
                                <Form.Label>Status</Form.Label>
                                <Form.Select name="statusId" size="sm" aria-label="Status" onChange={handleChange} >
                                    {statusItems.map((status, i) => (
                                        <option key={i} value={i}>{status}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br/></Form.Label>
                                <Button
                                    type='submit'
                                    size="sm"
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    //render datafiles
    const showData = () => {
        return (
            <Container fluid>
                <Row>
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Co #</th>
                                <th>Check Date</th>
                                <th>Description</th>
                                <th>Check Control Total</th>
                                <th>Owner Count</th>
                                <th>Owner Utilized</th>
                                <th style={{ textAlign: "center" }}>Non-Operated Statements</th>
                                <th>Status</th>
                                <th>Status Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataFiles.map((dataFile, i) => (
                                <tr key={i}>
                                    <td>{dataFile.companyNumber}</td>
                                    <td>{dataFile.checkDateString}</td>
                                    <td>{dataFile.description}</td>                                    
                                    <td>{dataFile.checkControlTotalString}</td>
                                    <td>{dataFile.ownerCount}</td>
                                    <td><div>{dataFile.ownerUtilizedPercent}</div><ProgressBar style={{ backgroundColor: "white" }} className="progress-thin"
                                        variant={(dataFile.ownerUtilizedPercent.replace("%", "") < 25 ? "danger" : dataFile.ownerUtilizedPercent.replace("%", "") < 50 ? "warning" : dataFile.ownerUtilizedPercent.replace("%", "") < 75 ? "info" : "success")}
                                        now={dataFile.ownerUtilizedPercent.replace("%", "")} /></td>
                                    <td style={{ textAlign: "center" }}><Button variant="primary" size="sm"
                                        onClick={() => navigate('/statements/' + dataFile.id)}
                                        disabled={dataFile.statusId == 1}>
                                            Get Statements
                                        </Button>
                                    </td> 
                                    {(() => {
                                        switch (dataFile.statusId) {
                                            case 1:
                                                return <td>File Load Processing</td>
                                            case 2:
                                                if (accountProfile.hasImpersonate) {
                                                    return <td style={{ textAlign: "left" }}><Button name="btnMap" variant="primary" size="sm"
                                                        onClick={pageStatus === 'ready' ? (e) => handleMappingsButton(e, dataFile) : null}>
                                                        Click to Map
                                                    </Button>
                                                    </td>
                                                } else {
                                                    return <td>Mapping Needed</td>
                                                }
                                            case 3:
                                                return <td style={{ textAlign: "left" }}><Button name="btnRelease" variant="primary" size="sm"
                                                    onClick={(e) => handleStatusChange(e, dataFile.id, dataFile.statusId, i)}>
                                                    Click to Send
                                                </Button>
                                                </td>
                                            case 4:
                                                return <td>Sent</td>
                                        }
                                    })()}
                                    <td>{dataFile.statusDateString}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Container>
        );
    }

    //render component
    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            <h1>Check Data Files</h1>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <MappingsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handlemapbutton={mappingButtonClick}
                datafile={selectedDataFile}
            />
        </div>
    );
}

export default DataFiles;
